import React, { useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleSlideMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div
        style={{ background: "#0070C0" }}
        className="flex justify-center items-center header z-50 top-0 sticky"
      >
        <div
          style={{ width: "1200px", height: "80px" }}
          className="flex items-center justify-between lg:mx-auto mx-2"
        >
          <a href={'/'} className="w-1/4 ">
            <img
              src="./circleit.png"
              alt="circleit"
              className="h-12 w-12 cursor-pointer rounded-full"
            />
          </a>
          <div className="w-2/5 lg:flex hidden">
            <div className="flex items-center text-white gap-10 justify-start">
              <Link to="/" className="cursor-pointer">
                Home
              </Link>
              <div
                className="relative"
                onMouseEnter={() => setIsDropdownVisible(true)}
                onMouseLeave={() => setIsDropdownVisible(false)}
              >
                <Link
                  to="#"
                  className="cursor-pointer transform transition-all flex justify-center items-center gap-2 duration-300 ease-in-out"
                >
                  Solutions{" "}
                  <svg
                    width="12px"
                    height="12px"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-1"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M7.49988 12L-0.00012207 4L14.9999 4L7.49988 12Z"
                        fill="#fff"
                      ></path>{" "}
                    </g>
                  </svg>
                </Link>
                {isDropdownVisible && (
                  <div className="absolute transform transition-all text-[#0070C0] duration-300 ease-in-out bg-white rounded-md shadow-lg mt-1 p-4">
                    <Link
                      to="/DataCollection"
                      onClick={()=> setIsDropdownVisible()}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      DataCollection
                    </Link>
                    <Link
                      to="/AudioSpeach"
                      onClick={()=> setIsDropdownVisible()}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      AudioSpeach
                    </Link>
                    <Link
                      to="/TextAnnotation"
                      onClick={()=> setIsDropdownVisible()}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      TextAnnotation
                    </Link>
                    <Link
                      to="/ImageAnnotation"
                      onClick={()=> setIsDropdownVisible()}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      ImageAnnotation
                    </Link>
                  </div>
                )}
              </div>
              <Link to="/Platforms">Platforms</Link>
              <Link to="/">Resources</Link>
            </div>
          </div>
          <div className="lg:w-1/3 w-3/4 hidden justify-between items-center lg:flex ">
            <div className="flex bg-white rounded-md w-3/5  justify-start items-center gap-2 px-2">
              <svg
                width="16px"
                height="16px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
              <input
                type="text"
                name="keyword"
                placeholder="Search..."
                className={`lg:py-1 py-2 duration-400 w-1/2 outline-none text-sm sm:text-base`}
              />
            </div>
            <Link
              style={{ background: "#035D9D" }}
              to={"/DataCollection#form"}
              className=" text-white py-2 justify-center items-center flex w-1/3 text-center rounded-md"
            >
              Get Started
            </Link>
          </div>
          <div className=" cursor-pointer lg:hidden">
            <svg
              onClick={toggleSlideMenu}
              width="40px"
              height="40px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M4 6H20M4 12H20M4 18H20"
                  stroke="#fff"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div
          className={`overlay-four ${
            isMenuOpen ? "slide-in" : "slide-out"
          } lg:hidden`}
        >
          <div className="w-full mx-8">
            <div className="w-full flex items-center justify-end mt-4">
              <div className="w-10 h-10 rounded-full flex items-center justify-center mr-12">
                <svg
                  onClick={toggleSlideMenu}
                  className="toggle-icon mr-1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M6 19L13 12L6 5L8 3L15 10L22 3L24 5L17 12L24 19L22 21L15 14L8 21L6 19Z" />
                </svg>
              </div>
            </div>
            <div className="text-[#2c3e50] font-bold text-[2rem] mb-5">
              Menu
            </div>

            <div className="flex flex-col items-start text-[#2c3e50] gap-4 justify-start">
              <Link onClick={toggleSlideMenu} to="/" className="cursor-pointer">
                Home
              </Link>
              <div
                className="relative"
                onMouseEnter={() => setIsDropdownVisible(true)}
                onMouseLeave={() => setIsDropdownVisible(false)}
              >
                <Link
                  to="#"
                  className="cursor-pointer transform transition-all flex justify-center items-center gap-2 duration-300 ease-in-out"
                >
                  Solutions{" "}
                  <svg
                    width="12px"
                    height="12px"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mt-1"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M7.49988 12L-0.00012207 4L14.9999 4L7.49988 12Z"
                        fill="#000"
                      ></path>{" "}
                    </g>
                  </svg>
                </Link>
                {isDropdownVisible && (
                  <div className="absolute transform transition-all text-[#0070C0] duration-300 ease-in-out bg-white rounded-md shadow-lg mt-1 p-4">
                    <Link
                      to="/DataCollection"
                      onClick={() => {
                        toggleSlideMenu();
                        setIsDropdownVisible();
                      }}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      DataCollection
                    </Link>
                    <Link
                      to="/AudioSpeach"
                      onClick={() => {
                        toggleSlideMenu();
                        setIsDropdownVisible();
                      }}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      AudioSpeach
                    </Link>
                    <Link
                      to="/TextAnnotation"
                      onClick={() => {
                        toggleSlideMenu();
                        setIsDropdownVisible();
                      }}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      TextAnnotation
                    </Link>
                    <Link
                      to="/ImageAnnotation"
                      onClick={() => {
                        toggleSlideMenu();
                        setIsDropdownVisible();
                      }}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      ImageAnnotation
                    </Link>
                  </div>
                )}
              </div>
              <Link
                onClick={() => {
                  toggleSlideMenu();
                  setIsDropdownVisible();
                }}
                to="/Platforms"
              >
                Platforms
              </Link>
              <Link
                onClick={() => {
                  toggleSlideMenu();
                  setIsDropdownVisible();
                }}
                to="/"
              >
                Resources
              </Link>
            </div>
          </div>
          <div className="lg:bg-[#0C831F] py-3 text-center md:text-sm text-xs bottom-0 w-full">
            Copyright © 2024{" "}
            <span className="text-[#0070C0] lg:text-white footer-store-name">
              CircleIt
            </span>{" "}
            | All Rights Reserved
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
