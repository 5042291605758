import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AudioSpeach from "./Pages/AudioSpeach";
import DataCollection from "./Pages/DataCollection";
import ImageAnnotation from "./Pages/ImageAnnotation";
import TextAnnotation from "./Pages/TextAnnotation";
import Layout from "./components/Layout";
import "./App.css"
import Platforms from "./Pages/Platforms";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/AudioSpeach" element={<AudioSpeach />} />
        <Route path="/DataCollection" element={<DataCollection />} />
        <Route path="/ImageAnnotation" element={<ImageAnnotation />} />
        <Route path="/TextAnnotation" element={<TextAnnotation />} />
        <Route path="/Platforms" element={<Platforms />} />
      </Routes>
    </Layout>
  );
}

export default App;
