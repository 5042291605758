import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const listItems = [
  { text: "Prompt Datasets" },
  { text: "Prompt Datasets" },
  { text: "Prompt Datasets" },
  { text: "Prompt Datasets" },
  { text: "Prompt Datasets" },
  { text: "Prompt Datasets" },
];

function DataCollection() {

  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.getElementById(location.hash.replace('#', ''));
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleDivClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' }); 
    }
  };
  
  return (
    <div>
      <div
        className="w-full flex justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url('/data.png')` }}
      >
        <div className="flex md:flex-row flex-col max-w-[1200px] md:mx-auto mx-2 py-16">
          <div className="md:w-1/2 w-full flex justify-center items-center flex-col ">
            <div className="w-3/4 flex flex-col gap-4">
              <h3 className="lg:text-2xl text-[#035D9D] font-bold">
                AI Data Solutions
              </h3>
              <h1 className="lg:text-5xl text-lg lg:w-5/6 w-1/2 font-bold text-white px-4 py-4 bg-[#035D9D] text-nowrap">
                Data Collection
              </h1>
              <p className="lg:text-lg text-sm font-bold text-gray-500">
                Customized Natural and Synthetic Data Collection for Generative
                and Traditional AI Model Training
              </p>
              <div
                 onClick={() => handleDivClick('form')}
                className=" text-white py-2 bg-[#035D9D] cursor-pointer border-2 text-sm font-semibold border-[#035D9D] hover:text-[#035D9D] hover:bg-white transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
              >
                Talk to an Expert
              </div>
            </div>
          </div>
          <div className="md:w-1/2 w-full ">
            <img src="./cube.png" alt="cube" className="w-full" />
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="flex lg:flex-row flex-col  max-w-[1200px] justify-center lg:mx-auto mx-2  items-center mt-10 mb-10">
          <div className="lg:w-1/2 ">
            <img src="./Dataset.png" alt="dataset" />
          </div>
          <div className="lg:w-1/2 w-full flex justify-center items-center">
            <p className="lg:w-3/4 text-center text-lg font-semibold text-gray-600 ">
              Let Innodata{" "}
              <span className="text-[#035D9D]">
                source, collect, and generate
              </span>{" "}
              speech, audio, image, video, text, and document training data for
              generative and traditional Al model development. With 85+
              languages supported across the globe, we offer customized data
              collection and creation offerings to meet any domain need.
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-4 py-10 mt-10 lg:mx-auto mx-2">
          <p className="text-3xl font-semibold text-center">
            Capture, Source, & Generate High-Quality Data for
          </p>
          <p className="text-3xl font-semibold text-[#035D9D] text-center">
            Exceptional AI/ML Model Development
          </p>
          <p className="text-center">
            Innodata creates customized datasets across a range of formats to
            train and fine-tune your AI models.
          </p>
          <a
            href={"/"}
            className=" text-white py-2 bg-[#035D9D] border-2 text-sm font-semibold border-[#035D9D] hover:text-[#035D9D] hover:bg-white transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
          >
            Talk to an Expert
          </a>
        </div>

        <div className="flex max-w-[1200px] gap-4 mt-10 md:flex-row flex-col justify-center items-center lg:mx-auto mx-2">
          <div className="md:w-1/3 w-4/5 border flex justify-center items-center flex-col rounded-lg shadow-lg">
            <img src="./text.png" alt="text" />
            <div className="w-3/4 flex flex-col gap-4 pb-4">
              <h2 className="text-xl text-gray-700 font-bold">
                Text & Documents
              </h2>
              <p className="text-gray-500 font-medium">
                Curated and generated datasets, from prompt datasets to
                financial documents, and more. Scale your AI models and ensure
                model flexibility with high-quality and diverse text data in
                multiple languages and formats.
              </p>
            </div>
            <div className="w-3/4">
              <p className="font-bold text-gray-600 mb-4">sample Datasets :</p>
              <ul className="flex flex-col gap-2">
                {listItems.map((item, index) => (
                  <li
                    key={index}
                    className="flex gap-2 justify-start items-center"
                  >
                    <span>
                      <svg
                        height="20px"
                        width="20px"
                        fill="#035D9D"
                        viewBox="0 0 1920 1920"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <path
                            d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                            fillRule="evenodd"
                          ></path>
                        </g>
                      </svg>
                    </span>
                    <p>{item.text}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-[#035D9D] h-4 w-full mt-10 rounded-b-lg"></div>
          </div>
          <div className=" md:w-1/3 w-4/5 border flex justify-center items-center flex-col rounded-lg shadow-lg">
            <img src="./Audio.png" alt="text" />
            <div className="w-3/4 flex flex-col gap-4 pb-4">
              <h2 className="text-xl text-gray-700 font-bold">
                Text & Documents
              </h2>
              <p className="text-gray-500 font-medium">
                Curated and generated datasets, from prompt datasets to
                financial documents, and more. Scale your AI models and ensure
                model flexibility with high-quality and diverse text data in
                multiple languages and formats.
              </p>
            </div>
            <div className="w-3/4">
              <p className="font-bold text-gray-600 mb-4">sample Datasets :</p>
              <ul className="flex flex-col gap-2">
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
              </ul>
            </div>
            <div className="bg-[#035D9D] h-4 w-full mt-10 rounded-b-lg"></div>
          </div>
          <div className="md:w-1/3 w-4/5 border flex justify-center items-center flex-col rounded-lg shadow-lg">
            <img src="./Image-Video.png" alt="text" />
            <div className="w-3/4 flex flex-col gap-4 pb-4">
              <h2 className="text-xl text-gray-700 font-bold">
                Text & Documents
              </h2>
              <p className="text-gray-500 font-medium">
                Curated and generated datasets, from prompt datasets to
                financial documents, and more. Scale your AI models and ensure
                model flexibility with high-quality and diverse text data in
                multiple languages and formats.
              </p>
            </div>
            <div className="w-3/4">
              <p className="font-bold text-gray-600 mb-4">sample Datasets :</p>
              <ul className="flex flex-col gap-2">
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
                <li className="flex gap-2 justify-start items-center">
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fill-rule="evenodd"
                        ></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <p>Prompt Datasets</p>
                </li>
              </ul>
            </div>
            <div className="bg-[#035D9D] h-4 w-full mt-10 rounded-b-lg"></div>
          </div>
        </div>

        <div className="max-w-[1200px] flex lg:flex-row flex-col justify-center items-center lg:justify-between py-20 mt-20">
          <div className="lg:w-1/2 w-4/5 flex flex-col gap-4">
            <h2 className="text-4xl font-bold">
              When Real-World Data Falls Short
            </h2>
            <p className="text-base font-medium ">
              Innodata goes beyond real-world data collection to offer
              comprehensive synthetic data creation as well. Synthetic data is
              artificially generated data that statistically mirrors real-world
              data. This empowers you to:
            </p>
            <div className="flex gap-4">
              <div className="flex-col flex gap-12 mt-2">
                <svg
                  fill="#000000"
                  width="24px"
                  height="24px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M13.49 3 10.74.37A1.22 1.22 0 0 0 9.86 0h-4a1.25 1.25 0 0 0-1.22 1.25v11a1.25 1.25 0 0 0 1.25 1.25h6.72a1.25 1.25 0 0 0 1.25-1.25V3.88a1.22 1.22 0 0 0-.37-.88zm-.88 9.25H5.89v-11h2.72v2.63a1.25 1.25 0 0 0 1.25 1.25h2.75zm0-8.37H9.86V1.25l2.75 2.63z"></path>
                    <path d="M10.11 14.75H3.39v-11H4V2.5h-.61a1.25 1.25 0 0 0-1.25 1.25v11A1.25 1.25 0 0 0 3.39 16h6.72a1.25 1.25 0 0 0 1.25-1.25v-.63h-1.25z"></path>
                  </g>
                </svg>
                <svg
                  fill="#000000"
                  width="24px"
                  height="24px"
                  viewBox="0 0 256 256"
                  id="Flat"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M232.00586,128.00842a298.25279,298.25279,0,0,1-5.59277,57.54688,7.99962,7.99962,0,0,1-15.69727-3.09375,282.27848,282.27848,0,0,0,5.29-54.45313,88,88,0,0,0-176,0,103.74958,103.74958,0,0,1-5.91992,34.666A8.00025,8.00025,0,0,1,19,157.34241a87.786,87.786,0,0,0,5.00586-29.334,104,104,0,0,1,208,0ZM93.00977,84.2887A8.00025,8.00025,0,0,0,83.00195,71.80432,71.69909,71.69909,0,0,0,56.00586,128.0094,118.80142,118.80142,0,0,1,43.52637,181.361,7.99964,7.99964,0,1,0,57.85254,188.484,134.64637,134.64637,0,0,0,72.00586,128.0094,55.77314,55.77314,0,0,1,93.00977,84.2887Zm34.99609,35.7207a8.00039,8.00039,0,0,0-8,8A184.12954,184.12954,0,0,1,97.03418,217.11a7.99993,7.99993,0,1,0,13.99219,7.75977,200.16824,200.16824,0,0,0,24.97949-96.86035A8.00039,8.00039,0,0,0,128.00586,120.0094Zm0-32a40.04583,40.04583,0,0,0-40,40,8,8,0,0,0,16,0,24,24,0,0,1,48,0,214.09658,214.09658,0,0,1-20.51074,91.99512,8,8,0,1,0,14.47265,6.82226,229.98738,229.98738,0,0,0,22.03809-98.81738A40.04583,40.04583,0,0,0,128.00586,88.0094ZM94.39648,152.16858a8.00778,8.00778,0,0,0-9.43066,6.249,150.78976,150.78976,0,0,1-17.2041,45.4375,8.00031,8.00031,0,1,0,13.85937,7.9961,166.73312,166.73312,0,0,0,19.02442-50.252A7.99971,7.99971,0,0,0,94.39648,152.16858ZM128.00586,56.0094a72.92874,72.92874,0,0,0-8.99121.55664A8.00012,8.00012,0,1,0,120.99707,72.443a56.70946,56.70946,0,0,1,7.00879-.43359,56.0629,56.0629,0,0,1,56,56,251.41194,251.41194,0,0,1-1.919,31.00879,8,8,0,1,0,15.877,1.98047,267.59018,267.59018,0,0,0,2.042-32.98926A72.08124,72.08124,0,0,0,128.00586,56.0094Zm57.92969,128.252a7.99377,7.99377,0,0,0-9.74121,5.75293c-1.46485,5.68848-3.15332,11.39063-5.01856,16.94824a7.9999,7.9999,0,1,0,15.168,5.0918c1.9873-5.91992,3.78613-11.99316,5.34473-18.05176A7.999,7.999,0,0,0,185.93555,184.26135Z"></path>{" "}
                  </g>
                </svg>
                <svg
                  width="24px"
                  height="24px"
                  viewBox="0 0 512 512"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>warning-filled</title>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      {" "}
                      <g
                        id="add"
                        fill="#000000"
                        transform="translate(32.000000, 42.666667)"
                      >
                        {" "}
                        <path
                          d="M246.312928,5.62892705 C252.927596,9.40873724 258.409564,14.8907053 262.189374,21.5053731 L444.667042,340.84129 C456.358134,361.300701 449.250007,387.363834 428.790595,399.054926 C422.34376,402.738832 415.04715,404.676552 407.622001,404.676552 L42.6666667,404.676552 C19.1025173,404.676552 7.10542736e-15,385.574034 7.10542736e-15,362.009885 C7.10542736e-15,354.584736 1.93772021,347.288125 5.62162594,340.84129 L188.099293,21.5053731 C199.790385,1.04596203 225.853517,-6.06216498 246.312928,5.62892705 Z M224,272 C208.761905,272 197.333333,283.264 197.333333,298.282667 C197.333333,313.984 208.415584,325.248 224,325.248 C239.238095,325.248 250.666667,313.984 250.666667,298.624 C250.666667,283.264 239.238095,272 224,272 Z M245.333333,106.666667 L202.666667,106.666667 L202.666667,234.666667 L245.333333,234.666667 L245.333333,106.666667 Z"
                          id="Combined-Shape"
                        >
                          {" "}
                        </path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
                <svg
                  fill="#000000"
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 31.612 31.612"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path d="M10.871,13.671l-4.058,4.057c-0.234,0.234-0.367,0.553-0.367,0.885c0,0.333,0.133,0.65,0.367,0.885l3.923,3.924 c0.245,0.244,0.565,0.367,0.887,0.367c0.32,0,0.641-0.123,0.885-0.367c0.49-0.488,0.49-1.281,0-1.771L9.47,18.613l3.173-3.172 c0.489-0.488,0.489-1.281,0-1.77C12.152,13.182,11.36,13.182,10.871,13.671z"></path>{" "}
                        <path d="M18.969,15.443l3.174,3.171l-3.039,3.038c-0.488,0.488-0.488,1.281,0,1.771c0.244,0.244,0.564,0.366,0.886,0.366 s0.642-0.122,0.887-0.366l3.923-3.924c0.234-0.234,0.367-0.554,0.367-0.886c0-0.333-0.133-0.651-0.367-0.886l-4.058-4.056 c-0.489-0.489-1.281-0.489-1.771,0C18.48,14.16,18.48,14.954,18.969,15.443z"></path>{" "}
                        <path d="M13.265,26.844c0.081,0.023,0.162,0.037,0.245,0.037c0.356,0,0.688-0.232,0.798-0.592l4.59-14.995 c0.138-0.441-0.111-0.908-0.553-1.043c-0.443-0.135-0.906,0.113-1.043,0.554L12.71,25.799 C12.576,26.241,12.823,26.707,13.265,26.844z"></path>{" "}
                        <path d="M11.216,0L3.029,8.643v22.969h25.554V0H11.216z M10.495,3.635v3.83H6.867L10.495,3.635z M26.605,29.637H5.005V9.441h7.465 V1.975h14.135V29.637z"></path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
              <ul className="flex flex-col gap-2">
                <li>
                  <p className="font-bold text-sm text-[#035D9D]">
                    Augment Real-World Data
                  </p>
                  <p className="text-sm">
                    Expand existing datasets with high-quality, synthetic
                    variations, enriching your models with diverse scenarios and
                    edge cases.
                  </p>
                </li>
                <li>
                  <p className="font-bold text-sm text-[#035D9D]">
                    Augment Real-World Data
                  </p>
                  <p className="text-sm">
                    Expand existing datasets with high-quality, synthetic
                    variations, enriching your models with diverse scenarios and
                    edge cases.
                  </p>
                </li>
                <li>
                  <p className="font-bold text-sm text-[#035D9D]">
                    Augment Real-World Data
                  </p>
                  <p className="text-sm">
                    Expand existing datasets with high-quality, synthetic
                    variations, enriching your models with diverse scenarios and
                    edge cases.
                  </p>
                </li>
                <li>
                  <p className="font-bold text-sm text-[#035D9D]">
                    Augment Real-World Data
                  </p>
                  <p className="text-sm">
                    Expand existing datasets with high-quality, synthetic
                    variations, enriching your models with diverse scenarios and
                    edge cases.
                  </p>
                </li>
              </ul>
            </div>
            <p className="text-lg font-medium">
              By utilizing real-world and/or synthetic data, Innodata empowers
              you to develop more robust and versatile AI/ML models.
            </p>
          </div>
          <div className="lg:w-2/5 w-4/5">
            <img src="./facial.png" alt="facial" className="w-full" />
          </div>
        </div>
        <div className="max-w-[1200px] flex flex-col justify-between items-center py-20 mt-20">
          <h2 className="text-4xl font-bold mb-10">
            Why <span className="text-[#035D9D]">Innodata?</span>
          </h2>
          <div className="flex lg:flex-row flex-col justify-center items-center mt-20 gap-20 lg:gap-4">
            <div className="lg:w-1/3 w-4/5 flex flex-col justify-center items-center gap-4 rounded-md shadow-xl">
              <img src="./global.png" alt="house" className="w-1/3 -mt-10" />
              <h3 className="w-3/4 text-gray-600 text-center text-lg font-bold">
                Global Delivery Centers & Language Capabilities
              </h3>
              <p className="px-4 text-sm font-medium text-center mb-10">
                Innodata operates global delivery centers proficient in over 85
                native languages and dialects, ensuring comprehensive language
                coverage for your projects.
              </p>
            </div>
            <div className="lg:w-1/3 w-4/5 flex flex-col justify-center items-center gap-4 rounded-md shadow-xl">
              <img src="./trimmy.png" alt="house" className="w-1/3 -mt-10" />
              <h3 className="w-3/4 text-gray-600 text-center text-lg font-bold">
                Global Delivery Centers & Language Capabilities
              </h3>
              <p className="px-4 text-sm font-medium text-center mb-10">
                Innodata operates global delivery centers proficient in over 85
                native languages and dialects, ensuring comprehensive language
                coverage for your projects.
              </p>
            </div>
            <div className="lg:w-1/3 w-4/5 flex flex-col justify-center items-center gap-4 rounded-md shadow-xl">
              <img src="./domain.png" alt="house" className="w-1/3 -mt-10" />
              <h3 className="w-3/4 text-gray-600 text-center text-lg font-bold">
                Global Delivery Centers & Language Capabilities
              </h3>
              <p className="px-4 text-sm font-medium text-center mb-10">
                Innodata operates global delivery centers proficient in over 85
                native languages and dialects, ensuring comprehensive language
                coverage for your projects.
              </p>
            </div>
          </div>
        </div>

        <div
        id="form"
          className="w-4/5 flex lg:flex-row flex-col justify-center items-center bg-cover rounded bg-center"
          style={{ backgroundImage: `url('/Form-Background.png')` }}
        >
          <div className="lg:w-1/2 flex justify-center items-center">
            <div className="w-3/4 flex flex-col lg:gap-8 gap-4 text-white mt-8 lg:mt-0">
              <h2 className="text-3xl font-bold text-center">
                Fuel Generative and Traditional AI with Innodata.
              </h2>
              <hr />
              <p className="text-lg font-medium text-center">
                High-quality data collection and creation for AI/ML model
                development.
              </p>
            </div>
          </div>
          <div className="lg:w-2/5 w-4/5 ">
            <div class="flex justify-center items-center w-full">
              <div class="container w-full lg:mx-auto my-4 px-4 flex justify-center items-center">
                <div class="lg:w-4/5 w-full p-8 my-4 md:px-12 rounded-lg shadow-2xl bg-white">
                  <div class="grid grid-cols-1 gap-5 mt-5">
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="First Name*"
                    />
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="text"
                      placeholder="Last Name*"
                    />
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="email"
                      placeholder="Email*"
                    />
                    <input
                      class="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                      type="number"
                      placeholder="Phone*"
                    />
                  </div>
                  <div class="my-4">
                    <textarea
                      placeholder="Message*"
                      class="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                    ></textarea>
                  </div>
                  <div class="my-2 lg:w-3/5">
                    <button
                      class="uppercase text-sm font-bold tracking-wide bg-blue-900 text-gray-100 p-3 rounded-lg w-full 
                      focus:outline-none focus:shadow-outline"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataCollection;
