import React from "react";
import Timeline from "../components/TimeLine";
import { Link } from "react-router-dom";

function ImageAnnotation() {
  return (
    <div>
      <div className="relative w-full h-screen overflow-hidden flex justify-center items-center">
        <iframe
          src="https://player.vimeo.com/video/502269632?muted=1&autoplay=1&loop=1&background=1&app_id=122963"
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Vimeo Background Video"
        />

        <div className="absolute inset-0 bg-[#035D9D] opacity-70 z-10"></div>

        <div className="relative z-10 max-w-[1200px] flex justify-center items-center md:items-start md:justify-start ">
          <div className="w-4/5 flex flex-col justify-center items-center md:items-start md:justify-start  gap-8">
            <p className=" font-bold text-white">Text Anotation</p>
            <h2 className="md:text-5xl text-2xl md:text-start text-center font-bold text-white">
              Image, Video, & Sensor Data Annotation
            </h2>
            <p className="md:text-xl text-lg font-medium text-white md:text-start text-center">
              High-Quality Training Data for AI/ML Model Development
            </p>
            <Link
              to={"/DataCollection#form"}
              className=" text-[#11416A] hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
            >
              Talk to an Expert
            </Link>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="200 0 500 100"
          preserveAspectRatio="#ffffff"
          transform="rotate(180)"
          className="fill-white bottom-0 -mb-1 z-10 absolute"
        >
          <path
            class="elementor-shape-fill fill-white"
            opacity="0.33"
            d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"
          ></path>
          <path
            class="elementor-shape-fill fill-white"
            opacity="0.66"
            d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"
          ></path>
          <path
            class="elementor-shape-fill fill-white"
            d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"
          ></path>
        </svg>
      </div>
      <div className="flex justify-center items-center mt-20">
        <div className="max-w-[1200px] flex flex-col justify-center items-center">
          <div className="flex flex-col w-4/5 gap-4 justify-center items-center">
            <h2 className="text-2xl md:text-start text-center font-semibold text-[#035D9D]">
              {" "}
              High-Quality Annotated Data Powering Computer Vision Technologies{" "}
            </h2>
            <p className="text-center">
              Delivering precision training, validation, and ground-truth data
              for dynamic images, video, and sensor data. Fusing AI-enabled
              platforms and subject matter experts, we accelerate your process
              without sacrificing quality so your team can focus on innovation.
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-20">
        <div className="max-w-[1200px] flex flex-col border-[#C4D602] pb-10 border-b-[1px] justify-center items-center">
          <video
            className="w-4/5 h-auto lg:w-1/2 shadow-lg"
            controls
            autoPlay
            muted
            loop
          >
            <source src="/Image-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <a
            href={"/"}
            className=" text-[#11416A] hover:text-white hover:scale-105 py-4 bg-[#C4D602] mt-4 text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/5 w-1/2 text-center rounded"
          >
            Connect With Us
          </a>
        </div>
      </div>

      <div className="flex justify-center flex-col items-center mt-20">
        <div className="max-w-[1200px] flex flex-col justify-center items-center">
          <h2 className="text-4xl text-[#035D9D] text-center font-medium">
            Powerful Tools to Train Your AI Models
          </h2>
          <div className="flex flex-col mt-10 gap-4">
            <div className="flex lg:flex-row flex-col gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-1/3 w-4/5">
                {" "}
                <img
                  src="./classifications.jpg"
                  alt=""
                  className=" rounded-lg"
                />
              </div>
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex-col flex gap-4 ">
                <h3 className="text-3xl text-[#035D9D] font-medium">
                  Classifications
                </h3>
                <p className="text-base text-gray-600">
                  Tags can be used to describe visible features and to classify
                  whole images,particular areas,items,and specific attributes.
                  Classification tags help organize and define categories that
                  train image and video classifiers. They are the foundation for
                  machine learning models and can be paired with other types of
                  annotation. Ideal for: search, recommendation engines, and
                  discovery platforms.
                </p>
              </div>
            </div>
            <div className="lg:flex-row flex flex-col-reverse gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex flex-col gap-4">
                <h3 className="text-3xl text-[#035D9D] font-medium">
                  Bounding Box
                </h3>
                <p className="text-base text-gray-600">
                  A bounding box is a rectangle drawn around the extremities of
                  an object of interest to define its X and Y coordinates. Ideal
                  for object identification, classification, and localization,
                  damage assessment for auto insurance, product identification
                  for retail and product anomaly detection for manufacturing.
                </p>
              </div>

              <div className="lg:w-1/3 w-4/5">
                <img
                  src="./bounding.jpg"
                  alt="Bounding Box"
                  className="rounded-lg"
                />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-1/3 w-4/5">
                {" "}
                <img src="./sea.jpg" alt="" className=" rounded-lg" />
              </div>
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex-col flex gap-4 ">
                <h3 className="text-3xl text-[#035D9D] font-medium">Polygon</h3>
                <p className="text-base text-gray-600">
                  Polygons allow annotators to create pixel-perfect silhouettes
                  of amorphous entities. This accuracy is crucial for Object
                  Detection, Semantic Segmentation, Instance Segmentation,
                  Volumetric Segmentation, and composite objects. Ideal for:
                  search, recommendation engines, and discovery platforms.
                </p>
              </div>
            </div>
            <div className="lg:flex-row flex flex-col-reverse gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex flex-col gap-4">
                <h3 className="text-3xl text-[#035D9D] font-medium">
                  Lines and Splines
                </h3>
                <p className="text-base text-gray-600">
                  Lines are a series of points that can be used to define slope,
                  direction, or edge. They define non-visible attributes of
                  objects like mid-points and trajectories. Splines are a series
                  of continuous irregular curves that allow the annotator to
                  create a curve that is as close to a perfect fit of the data
                  points as possible. Lines and splines are used to train
                  regressor networks and keypoint detectors. Ideal for defining
                  lane markings and interpolation.
                </p>
              </div>

              <div className="lg:w-1/3 w-4/5">
                <img
                  src="./lines.jpg"
                  alt="Bounding Box"
                  className="rounded-lg"
                />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-1/3 w-4/5">
                {" "}
                <img src="./recognition.jpg" alt="" className=" rounded-lg" />
              </div>
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex-col flex gap-4 ">
                <h3 className="text-3xl text-[#035D9D] font-medium">
                  Facial Recognition
                </h3>
                <p className="text-base text-gray-600">
                  Facial recognition relies on distinguishing subtle key
                  attributes of a person face at a range of angles and in
                  diverse lighting. Several tools including bounding boxes,
                  persistent ID tracking across frames, and facial keypoints can
                  be used in tandem to facilitate facial recognition. Ideal for
                  security and identity confirmation, driver safety, organizing
                  photography and video, emotional intelligence, and social
                  media.
                </p>
              </div>
            </div>
            <div className="lg:flex-row flex flex-col-reverse gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex flex-col gap-4">
                <h3 className="text-3xl text-[#035D9D] font-medium">
                  Point Cloud
                </h3>
                <p className="text-base text-gray-600">
                  By fusing 3D LiDAR sensor data with 2D images and videos,
                  annotators are able to create cuboid annotations to track 3
                  dimensional objects across space and time. Ideal for
                  autonomous vehicles, manufacturing, and agriculture.
                </p>
              </div>

              <div className="lg:w-1/3 w-4/5">
                <img
                  src="./PointCloud.jpg"
                  alt="Bounding Box"
                  className="rounded-lg"
                />
              </div>
            </div>
            <div className="flex lg:flex-row flex-col gap-4 justify-around items-center rounded-md py-4 hover:shadow-lg transform transition-all duration-300 ease-in-out">
              <div className="lg:w-1/3 w-4/5">
                {" "}
                <img src="./Runners.jpg" alt="" className=" rounded-lg" />
              </div>
              <div className="lg:w-3/5 w-4/5 text-center lg:text-start flex-col flex gap-4 ">
                <h3 className="text-3xl text-[#035D9D] font-medium">
                  Keypoint
                </h3>
                <p className="text-base text-gray-600">
                  A point that defines an object or demarcates part of an
                  object. A series of keypoints is called a point map, which is
                  used to show the pose of an object. Ideal for keypoint
                  detection & mapping, showing movement, and 6Dof object pose,
                  sports analysis and medicine, virtual and augmented reality,
                  robotic manipulation and visual navigation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center flex-col items-center mt-20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="300 0 700 100"
          preserveAspectRatio="#10416A14"
        >
          <path
            class="elementor-shape-fill fill-[#10416A14]"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
        <div className="max-w-[1200px] mt-20 flex-col flex gap-4 md:text-start text-center ">
          <p className="text-gray-500 font-medium uppercase lg:w-[1200px]">
            The Innodata Process
          </p>
          <h2 className="md:text-5xl text-3xl text-[#035D9D] mb-20 lg:w-[1200px]">
            An End-to-End Approach
          </h2>
        </div>
        <div className="max-w-[1200px] flex flex-col justify-center items-center mb-20 border-[#C4D602] border-b-[1px]">
          <Timeline />
        </div>
        <div className="max-w-[1200px] mt-20 flex md:flex-row flex-col justify-center items-center ">
          <div className="md:w-1/2 w-4/5 flex flex-col gap-4 justify-center items-center md:justify-start md:items-start md:text-start text-center">
            <h2 className="text-2xl font-medium text-[#035D9D]">
              Our Team of Data Experts
            </h2>
            <p className="text-gray-500">
              Our team is comprised of data experts with years of developing
              strategies that enable companies to manage and distribute data
              using AI-based solutions. Book a time that works for you, and let
              us help develop a custom solution for your unique needs
            </p>
            <a
              href={"/"}
              className=" text-[#11416A] uppercase hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
            >
              REQUEST A DEMO
            </a>
          </div>
          <div className="md:w-1/2 w-4/5 mt-4 md:mt-0 flex justify-center items-center">
            <img src="./Screen-Shot.jpg" alt="" className="rounded-md" />
          </div>
        </div>
        <div className="bg-[#10416A14] w-full mt-20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="200 0 800 100"
          preserveAspectRatio="#ffffff"
        >
          <path
            class="elementor-shape-fill fill-[#ffffff]"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
        <div
        className="relative w-full h-screen flex flex-col justify-center items-center z-10 bg-cover bg-center"
        style={{ backgroundImage: `url('/graphicBackground.png')` }}
      >
        <div className="max-w-[1200px] flex gap-4 flex-col justify-center items-start ">
          <div className="flex md:flex-row flex-col justify-center w-full items-starat gap-10">
            <div className="md:w-1/4 w-4/5 bg-white flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./data-centeric.jpg" alt="" className="rounded" />
              <h2 className="text-2xl text-[#11416A] font-medium text-center">
                Facial Recognition
              </h2>
              <p className="text-sm text-center text-gray-500 mb-28">
                Preparing matching image sets · Identifying unique attributes ·
                Matching accessories · etc
              </p>
            </div>
            <div className="md:w-1/4 w-4/ bg-white  flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img
                src="./Multiple-Configurations.jpg"
                alt=""
                className="rounded"
              />
              <h2 className="text-2xl text-[#11416A] font-medium text-center">
                Object & Metadata Identification
              </h2>
              <p className="text-sm text-center text-gray-500">
                Any object and data of interest such as: Vehicles · Homes ·
                Buildings · Roofs · Shipments · Containers · Packages
              </p>
            </div>
            <div className="md:w-1/4 w-4/5 bg-white flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Secure-Text.jpg" alt="" className="rounded" />
              <h2 className="text-2xl text-[#11416A] font-medium text-center">Robotics</h2>
              <p className="text-sm text-center text-gray-500">
                Environment Mapping & Learning · Obstacle Detection · Product
                Anomaly Identification · Preventive Maintenance
              </p>
            </div>
          </div>
          
        </div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default ImageAnnotation;
