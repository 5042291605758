import React from "react";
import { Link } from "react-router-dom";

function HomePage() {
  const services = [
    {
      title: "Text Annotation Services for NLP",
      description:
        "Improve the accuracy of your Natural Language Processing (NLP) models with our comprehensive Text Annotation services. We provide precise labeling, entity recognition, and sentiment analysis to help streamline your AI workflows.",
    },
    {
      title: "Image Annotation for Computer Vision",
      description:
        "Empower your computer vision models with our advanced Image Annotation services. From bounding boxes to semantic segmentation, we deliver high-quality labeled data to train and enhance your AI systems.",
    },
    {
      title: "Audio & Speech Annotation Solutions",
      description:
        "Leverage our Audio Speech Annotation to transcribe and label speech data accurately. Our services support automatic speech recognition (ASR) systems and voice-based applications to improve voice recognition capabilities.",
    },
    {
      title: "Data Collection for AI and Machine Learning",
      description:
        "Drive your AI models with structured, scalable Data Collection solutions. We gather high-quality data from various sources to ensure your AI initiatives succeed with diverse and accurate datasets.",
    },
  ];
  return (
    <div>
      <div>
        <div className="w-full h-screen absolute">
          <video
            className="absolute w-full h-full object-cover"
            autoPlay
            loop
            muted
          >
            <source src="./herohome.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="relative w-full z-10 flex items-center justify-center h-screen md:-mt-40 ">
          <div
            style={{ width: "1200px" }}
            className="py-16 gap-4 justify-center items-center flex flex-col"
          >
            <h1 className="text-white text-3xl w-3/5 md:text-6xl font-bold text-center">
              Circleit - Leading Data Annotation Service Provider in India
            </h1>
            <p className="md:text-xl text-white font-medium md:font-bold w-3/5 text-center">
              Streamline your AI initiatives with accurate, secure, and scalable
              data annotation and labelling services tailored to meet the
              demands of modern businesses.
            </p>
            <Link
              to={"/DataCollection#form"}
              className="text-base text-white mt-4 hover:bg-white hover:text-[#0070C0] font-bold border-2 px-8 transform transition-all duration-300 ease-in-out py-2 rounded-md border-white"
            >
              Get Started{" "}
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full bg-white mb-10 ">
        <div className="relative py-6 pt-10 overflow-hidden bg-white mb-4">
          <div className="flex animate-marquee whitespace-nowrap">
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
          </div>
        </div>
        <div className="h-12 overflow-hidden bg-white">
          <div className="flex animate-marquee-two whitespace-nowrap">
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
            <img src="./BBC.png" alt="circleit" className="h-10 mx-4" />
            <img src="./Boeing.png" alt="circleit" className="h-10 mx-4" />
            <img src="./COSCOCS.png" alt="circleit" className="h-10 mx-4" />
          </div>
        </div>
      </div>

      <div className="blog-container">
        <div className="h-20 bg-gray-300 flex justify-center items-center">
          <h1 className="relative  mt-24 text-center md:text-2xl text-sm w-1/3 font-bold z-10">
            Innodata provides the full-suite of services and products you need
            to power your data-centric AI initiatives.
          </h1>
        </div>
        <div className="relative flex justify-center items-center py-20">
          <div className="absolute inset-0 bg-gray-300 clip-v-shape-downward"></div>
        </div>

        <div className="flex justify-center items-center mt-20">
          <div className="flex max-w-[1200px] md:flex-row gap-4 flex-col-reverse justify-around items-center border-b-[1px] pb-20">
            <div className="flex flex-col gap-4 justify-center lg:w-1/2 w-4/5 lg:px-10 items-start">
              <p className="font-bold text-[#0070C0]">AI Lifecycle Solutions</p>
              <h1 className="text-3xl font-bold">Generative AI Services</h1>
              <h3 className="font-bold text-xl">
                Whether your company chooses to build its own models, fine-tune
                existing models, or adopt a large language model as-is, Innodata
                offers end-to-end services and support.
              </h3>
              <a
                href={"/TextAnnotation"}
                className="text-base text-white mt-4 bg-[#0070C0] hover:bg-white hover:border-[#0070C0] hover:text-[#0070C0] font-bold border-2 px-8 transform transition-all duration-300 ease-in-out py-2 rounded-md border-white"
              >
                Explore
              </a>
            </div>
            <img src="./painting.jpg" alt="circleit" className="rounded-md" />
          </div>
        </div>
        <div className="flex justify-center items-center mt-20">
          <div className="flex max-w-[1200px] justify-around md:flex-row gap-4 flex-col items-center border-b-[1px] pb-20">
            <img src="./laptop.jpg" alt="circleit" className="rounded-md" />
            <div className="flex flex-col gap-4 justify-center lg:w-1/2 w-4/5 lg:px-10 items-start">
              <p className="font-bold text-[#0070C0]">
                Build, Maintain & Augment Your Models
              </p>
              <h1 className="text-3xl font-bold">Data Collection</h1>
              <h3 className="font-bold text-xl">
                Customized natural and synthetic data collection for AI model
                training.
              </h3>
              <a
                href={"/DataCollection"}
                className="text-base text-white mt-4 bg-[#0070C0] hover:bg-white hover:border-[#0070C0] hover:text-[#0070C0] font-bold border-2 px-8 transform transition-all duration-300 ease-in-out py-2 rounded-md border-white"
              >
                Explore
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-20">
          <div className="flex max-w-[1200px] md:flex-row gap-4 flex-col-reverse justify-around items-center border-b-[1px] pb-20">
            <div className="flex flex-col gap-4 justify-center lg:w-1/2 w-4/5 lg:px-10 items-start">
              <p className="font-bold text-[#0070C0]">Train AI and ML Models</p>
              <h1 className="text-3xl font-bold">Data Annotation</h1>
              <h3 className="font-bold text-xl">
                We securely and efficiently collect & label your most complex
                and sensitive data, delivering near-100% accurate ground truth
                for AI and ML models.
              </h3>
              <a
                href={"/ImageAnnotation"}
                className="text-base text-white mt-4 bg-[#0070C0] hover:bg-white hover:border-[#0070C0] hover:text-[#0070C0] font-bold border-2 px-8 transform transition-all duration-300 ease-in-out py-2 rounded-md border-white"
              >
                Explore
              </a>
            </div>
            <img src="./screen.jpg" alt="circleit" className="rounded-md" />
          </div>
        </div>
        <div className="flex justify-center items-center mt-20">
          <div className="flex max-w-[1200px] justify-around md:flex-row gap-4 flex-col items-center  pb-20">
            <img src="./group.jpg" alt="circleit" className="rounded-md" />
            <div className="flex flex-col gap-4 justify-center lg:w-1/2 w-4/5 lg:px-10 items-start">
              <p className="font-bold text-[#0070C0]">
                Harness Powerful AI In Your Operations
              </p>
              <h1 className="text-3xl font-bold">Applied AI Services</h1>
              <h3 className="font-bold text-xl">
                Achieve operational efficiencies in routine, knowledge-intensive
                work with AI-first expert augmentation.
              </h3>
              <a
                href={"/AudioSpeach"}
                className="text-base text-white mt-4 bg-[#0070C0] hover:bg-white hover:border-[#0070C0] hover:text-[#0070C0] font-bold border-2 px-8 transform transition-all duration-300 ease-in-out py-2 rounded-md border-white"
              >
                Explore
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-10">
        <div
          className="relative w-full h-screen flex flex-col justify-center items-center bg-cover bg-center"
          style={{ backgroundImage: `url('/Flexibility.jpg')` }}
        >
          <div className="absolute inset-0 bg-[#035D9D] opacity-70"></div>
          <div className="absolute z-10 w-full top-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="100 0 500 100"
              preserveAspectRatio="#0070C0"
              className=" transform scale-x-[-1]"
            >
              <path
                class=" fill-white"
                d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
              ></path>
            </svg>
          </div>

          <div className="relative z-10 text-white flex flex-col lg:w-1/5 justify-center items-center">
            <h1 className="text-3xl lg:text-5xl text-center font-bold">
              Intelligent Platforms
            </h1>
            <p className="md:text-xl text-base text-center font-medium mt-4 ">
              Easy-to-Use, Mission-Specific Platforms Built with the Highest
              Quality Data + AI
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center">
        <div className="max-w-[1400px] flex lg:flex-row flex-col justify-center mt-20 gap-4 items-center">
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <h2 className="lg:text-5xl text-3xl font-bold text-center mb-12 text-[#035D9D]">Our Data Annotation Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {services.map((service, index) => (
          <div key={index} className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold mb-4">{service.title}</h3>
            <p className="text-gray-700">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
        </div>
      </div>

      <div className="bg-white text-center mt-16 shadow-2xl pt-4">
        <div className="bg-[#10416A] text-white py-16 flex gap-4 flex-col justify-center items-center">
        <h2 className="lg:text-3xl font-bold text-xl">CircleIt - Annotation Services Arm of Lunarlogic Marketing Services LLP</h2>
        <p><span className="font-bold">Registered Address:</span> #501, Tower 7, Emaar Palm Gardens, Sector 83, Gurugram - 122004, India</p>
        <p><span className="font-bold">Office Address:</span>  91springboard, B1/H3, Block B, Mohan Estate, New Delhi - 110044</p>
          <h3 className="text-2xl mb-2 mt-10">Contact Us to Get Started</h3>
          <div className="space-y-3">
            <p><span className="font-bold">Contact: </span>+917669270784</p>
            <p> <span className="font-bold">Email: </span>info@lunarlogic.in</p>
          </div>
        
        </div>
      </div>
    </div>
  );
}

export default HomePage;
