import React from "react";
import Timeline from "../components/TimeLine";
import { Link } from "react-router-dom";

const listItems = [
  { text: "Dedicated Account Manager" },
  { text: "Team of Subject Matter Experts" },
  { text: "Prompt Datasets" },
  { text: "Project Management Dashboard" },
  { text: "Custom Reports" },
  { text: "Customized SLAs" },
  { text: "KPI Metrics" },
  { text: "API Connection" },
  { text: "Streamlined NDA Process" },
  { text: "Transparent Pricing" },
  { text: "World-Class 24/7 Support" },
  { text: "Project Management Dashboard" }
];

function Platforms() {
  return (
    <div className="w-full">
      <div
        className="relative w-full h-screen flex flex-col justify-center items-center bg-cover bg-center"
        style={{ backgroundImage: `url('/city.jpg')` }}
      >
        <div className="absolute inset-0 bg-[#035D9D] opacity-70"></div>
        <div className="relative z-10 max-w-[1200px] flex justify-center items-center md:items-start md:justify-start ">
          <div className="w-4/5 flex flex-col justify-center items-center md:items-start md:justify-start  gap-8">
            <p className=" font-bold text-white">Platforms</p>
            <h2 className="md:text-5xl text-2xl md:text-start text-center font-bold text-white">
            Annotation Platform
            </h2>
            <p className="md:text-xl text-lg font-medium text-white md:text-start text-center">
            Combining Powerful Workbenches & Pre-Trained Models
            </p>
            <Link
              to={"/DataCollection#form"}
              className=" text-[#11416A] hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
            >
              Get Started
            </Link>
          </div>
          {/* <div className="w-1/2">
          <iframe
          src="https://player.vimeo.com/video/502269632?muted=1&autoplay=1&loop=1&background=1&app_id=122963"
          className="absolute top-0 left-0 w-full h-full object-cover z-0"
          allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
          title="Vimeo Background Video"
        />
        </div> */}
        </div>
      </div>
      <div className="flex justify-center items-center mt-20">
        <div className="max-w-[1200px] flex flex-col justify-center items-center">
          <div className="flex flex-col w-4/5 gap-4 justify-center items-center">
            <h2 className="text-3xl md:text-start text-center font-semibold text-[#035D9D]">
              {" "}
              High-Quality Text Annotation and Classification Services{" "}
            </h2>
            <p className="text-center">
              With Innodata full suite of text annotation and classification
              services, you can scale your AI models and ensure model
              flexibility with high-quality annotated text data. Leverage
              Innodata deep annotation expertise to streamline text annotation
              and classification using active learning, NLP, and human
              experts-in-the-loop.
            </p>
          </div>
          <div className="flex md:flex-row flex-col justify-center items-center gap-4 mt-20">
            <div className="md:w-1/3 w-4/5 flex flex-col gap-2 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./data-centeric.jpg" alt="" className="rounded" />
              <h2 className="text-lg font-bold text-center">
                Data-Centric Approach
              </h2>
              <p className="text-sm text-center text-gray-500">
                Our data-centric approach helps jump-start your models with the
                highest quality of labeled text data for your AI/ML models.
              </p>
            </div>
            <div className="md:w-1/3 w-4/5 flex flex-col gap-2 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img
                src="./Multiple-Configurations.jpg"
                alt=""
                className="rounded"
              />
              <h2 className="text-lg font-bold text-center">
                Data-Centric Approach
              </h2>
              <p className="text-sm text-center text-gray-500">
                Our data-centric approach helps jump-start your models with the
                highest quality of labeled text data for your AI/ML models.
              </p>
            </div>
            <div className="md:w-1/3 w-4/5 flex flex-col gap-2 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Secure-Text.jpg" alt="" className="rounded" />
              <h2 className="text-lg font-bold text-center">
                Data-Centric Approach
              </h2>
              <p className="text-sm text-center text-gray-500">
                Our data-centric approach helps jump-start your models with the
                highest quality of labeled text data for your AI/ML models.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 500 100"
          preserveAspectRatio="#10416A14"
          transform="rotate(180)"
        >
          <path
            class="elementor-shape-fill fill-[#10416A14]"
            d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"
          ></path>
        </svg>
        <div className="bg-[#10416A14] w-full flex flex-col justify-center items-center">
          <div className="max-w-[1200px] flex flex-col justify-center items-center mb-4">
          <div className="flex flex-col justify-center items-center gap-6 mb-4 md:w-full w-4/5">
            <h2 className="md:text-4xl text-2xl font-bold text-center text-[#035D9D]">
              Our Expertise at Work Across Diverse Applications
            </h2>
            <p className="text-center md:text-lg text-sm">
              Whether you need document classification or NER annotation to
              automate document recognition or build your NLP models, our
              best-in-class text annotation solution delivers ground truth data
              for any situation in 40+ languages.
            </p>
            <Link
              to={"/DataCollection#form"}
              className=" text-[#11416A] hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/5 w-1/2 text-center rounded"
            >
              Get Started
            </Link>
            </div>
          </div>
          <div className="max-w-[1200px] flex justify-center items-center py-10 ">
            <ul className="grid md:grid-cols-2 gap-8 md:w-full w-4/5">
              <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="42px"
                    height="42px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M3 12H21M12 8V12M6.5 12V16M17.5 12V16M10.1 8H13.9C14.4601 8 14.7401 8 14.954 7.89101C15.1422 7.79513 15.2951 7.64215 15.391 7.45399C15.5 7.24008 15.5 6.96005 15.5 6.4V4.6C15.5 4.03995 15.5 3.75992 15.391 3.54601C15.2951 3.35785 15.1422 3.20487 14.954 3.10899C14.7401 3 14.4601 3 13.9 3H10.1C9.53995 3 9.25992 3 9.04601 3.10899C8.85785 3.20487 8.70487 3.35785 8.60899 3.54601C8.5 3.75992 8.5 4.03995 8.5 4.6V6.4C8.5 6.96005 8.5 7.24008 8.60899 7.45399C8.70487 7.64215 8.85785 7.79513 9.04601 7.89101C9.25992 8 9.53995 8 10.1 8ZM15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V17.6C21 17.0399 21 16.7599 20.891 16.546C20.7951 16.3578 20.6422 16.2049 20.454 16.109C20.2401 16 19.9601 16 19.4 16H15.6C15.0399 16 14.7599 16 14.546 16.109C14.3578 16.2049 14.2049 16.3578 14.109 16.546C14 16.7599 14 17.0399 14 17.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21ZM4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V17.6C10 17.0399 10 16.7599 9.89101 16.546C9.79513 16.3578 9.64215 16.2049 9.45399 16.109C9.24008 16 8.96005 16 8.4 16H4.6C4.03995 16 3.75992 16 3.54601 16.109C3.35785 16.2049 3.20487 16.3578 3.10899 16.546C3 16.7599 3 17.0399 3 17.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21Z"
                        stroke="#11416A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium md:text-start uppercase text-[#11416A]">
                    Content Classification
                  </h2>
                  <p className="texts-sm">
                    Build binary classifiers and other classification models for
                    automatically categorizing your content.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    fill="#11416A"
                    width="42px"
                    height="42px"
                    viewBox="-3 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>group</title>{" "}
                      <path d="M20.906 20.75c1.313 0.719 2.063 2 1.969 3.281-0.063 0.781-0.094 0.813-1.094 0.938-0.625 0.094-4.563 0.125-8.625 0.125-4.594 0-9.406-0.094-9.75-0.188-1.375-0.344-0.625-2.844 1.188-4.031 1.406-0.906 4.281-2.281 5.063-2.438 1.063-0.219 1.188-0.875 0-3-0.281-0.469-0.594-1.906-0.625-3.406-0.031-2.438 0.438-4.094 2.563-4.906 0.438-0.156 0.875-0.219 1.281-0.219 1.406 0 2.719 0.781 3.25 1.938 0.781 1.531 0.469 5.625-0.344 7.094-0.938 1.656-0.844 2.188 0.188 2.469 0.688 0.188 2.813 1.188 4.938 2.344zM3.906 19.813c-0.5 0.344-0.969 0.781-1.344 1.219-1.188 0-2.094-0.031-2.188-0.063-0.781-0.188-0.344-1.625 0.688-2.25 0.781-0.5 2.375-1.281 2.813-1.375 0.563-0.125 0.688-0.469 0-1.656-0.156-0.25-0.344-1.063-0.344-1.906-0.031-1.375 0.25-2.313 1.438-2.719 1-0.375 2.125 0.094 2.531 0.938 0.406 0.875 0.188 3.125-0.25 3.938-0.5 0.969-0.406 1.219 0.156 1.375 0.125 0.031 0.375 0.156 0.719 0.313-1.375 0.563-3.25 1.594-4.219 2.188zM24.469 18.625c0.75 0.406 1.156 1.094 1.094 1.813-0.031 0.438-0.031 0.469-0.594 0.531-0.156 0.031-0.875 0.063-1.813 0.063-0.406-0.531-0.969-1.031-1.656-1.375-1.281-0.75-2.844-1.563-4-2.063 0.313-0.125 0.594-0.219 0.719-0.25 0.594-0.125 0.688-0.469 0-1.656-0.125-0.25-0.344-1.063-0.344-1.906-0.031-1.375 0.219-2.313 1.406-2.719 1.031-0.375 2.156 0.094 2.531 0.938 0.406 0.875 0.25 3.125-0.188 3.938-0.5 0.969-0.438 1.219 0.094 1.375 0.375 0.125 1.563 0.688 2.75 1.313z"></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Intent Identification
                  </h2>
                  <p className="texts-sm">
                    Analyze the intent behind user-generated content to
                    determine the proper response or course of action.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#11416A"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>
                        communication / 16 - communication, bubble, chat,
                        comments, conversation, message icon
                      </title>{" "}
                      <g
                        id="Free-Icons"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        {" "}
                        <g
                          transform="translate(-1265.000000, -156.000000)"
                          id="Group"
                          stroke="#11416A"
                          stroke-width="2"
                        >
                          {" "}
                          <g
                            transform="translate(1263.000000, 154.000000)"
                            id="Shape"
                          >
                            {" "}
                            <path d="M8.29997591,16.1586109 L4.71,18.3 L5.32628923,14.6238888 C3.83281239,13.3921212 3,11.6856969 3,9.8 C3,6.0444637 6.30329488,3 11.55,3 C16.7967051,3 20.1,6.0444637 20.1,9.8 C20.1,13.5555363 16.7967051,16.6 11.55,16.6 C10.3617053,16.6 9.27309774,16.4438342 8.29997591,16.1586109 Z">
                              {" "}
                            </path>{" "}
                            <path d="M19.7000346,12 C20.5370398,12.8311353 21,13.932301 21,15.1401596 C21,16.4401458 20.4637233,17.6165428 19.5020218,18.4657147 L19.8988718,21 L17.587162,19.5237413 C16.9605351,19.7203723 16.259543,19.8280319 15.4943589,19.8280319 C12.7385072,19.8280319 10.8153028,18.4315592 10.2,16.5116586 L10.2,16.5116586">
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Content Detection
                  </h2>
                  <p className="texts-sm">
                    Automatically detect the types of content present in textual
                    data to support content moderation, such as hate speech and
                    other types of inappropriate content.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="42px"
                    height="42px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M3 12H21M12 8V12M6.5 12V16M17.5 12V16M10.1 8H13.9C14.4601 8 14.7401 8 14.954 7.89101C15.1422 7.79513 15.2951 7.64215 15.391 7.45399C15.5 7.24008 15.5 6.96005 15.5 6.4V4.6C15.5 4.03995 15.5 3.75992 15.391 3.54601C15.2951 3.35785 15.1422 3.20487 14.954 3.10899C14.7401 3 14.4601 3 13.9 3H10.1C9.53995 3 9.25992 3 9.04601 3.10899C8.85785 3.20487 8.70487 3.35785 8.60899 3.54601C8.5 3.75992 8.5 4.03995 8.5 4.6V6.4C8.5 6.96005 8.5 7.24008 8.60899 7.45399C8.70487 7.64215 8.85785 7.79513 9.04601 7.89101C9.25992 8 9.53995 8 10.1 8ZM15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V17.6C21 17.0399 21 16.7599 20.891 16.546C20.7951 16.3578 20.6422 16.2049 20.454 16.109C20.2401 16 19.9601 16 19.4 16H15.6C15.0399 16 14.7599 16 14.546 16.109C14.3578 16.2049 14.2049 16.3578 14.109 16.546C14 16.7599 14 17.0399 14 17.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21ZM4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V17.6C10 17.0399 10 16.7599 9.89101 16.546C9.79513 16.3578 9.64215 16.2049 9.45399 16.109C9.24008 16 8.96005 16 8.4 16H4.6C4.03995 16 3.75992 16 3.54601 16.109C3.35785 16.2049 3.20487 16.3578 3.10899 16.546C3 16.7599 3 17.0399 3 17.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21Z"
                        stroke="#11416A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Content Classification
                  </h2>
                  <p className="texts-sm">
                    Build binary classifiers and other classification models for
                    automatically categorizing your content.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    fill="#11416A"
                    width="42px"
                    height="42px"
                    viewBox="-3 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>group</title>{" "}
                      <path d="M20.906 20.75c1.313 0.719 2.063 2 1.969 3.281-0.063 0.781-0.094 0.813-1.094 0.938-0.625 0.094-4.563 0.125-8.625 0.125-4.594 0-9.406-0.094-9.75-0.188-1.375-0.344-0.625-2.844 1.188-4.031 1.406-0.906 4.281-2.281 5.063-2.438 1.063-0.219 1.188-0.875 0-3-0.281-0.469-0.594-1.906-0.625-3.406-0.031-2.438 0.438-4.094 2.563-4.906 0.438-0.156 0.875-0.219 1.281-0.219 1.406 0 2.719 0.781 3.25 1.938 0.781 1.531 0.469 5.625-0.344 7.094-0.938 1.656-0.844 2.188 0.188 2.469 0.688 0.188 2.813 1.188 4.938 2.344zM3.906 19.813c-0.5 0.344-0.969 0.781-1.344 1.219-1.188 0-2.094-0.031-2.188-0.063-0.781-0.188-0.344-1.625 0.688-2.25 0.781-0.5 2.375-1.281 2.813-1.375 0.563-0.125 0.688-0.469 0-1.656-0.156-0.25-0.344-1.063-0.344-1.906-0.031-1.375 0.25-2.313 1.438-2.719 1-0.375 2.125 0.094 2.531 0.938 0.406 0.875 0.188 3.125-0.25 3.938-0.5 0.969-0.406 1.219 0.156 1.375 0.125 0.031 0.375 0.156 0.719 0.313-1.375 0.563-3.25 1.594-4.219 2.188zM24.469 18.625c0.75 0.406 1.156 1.094 1.094 1.813-0.031 0.438-0.031 0.469-0.594 0.531-0.156 0.031-0.875 0.063-1.813 0.063-0.406-0.531-0.969-1.031-1.656-1.375-1.281-0.75-2.844-1.563-4-2.063 0.313-0.125 0.594-0.219 0.719-0.25 0.594-0.125 0.688-0.469 0-1.656-0.125-0.25-0.344-1.063-0.344-1.906-0.031-1.375 0.219-2.313 1.406-2.719 1.031-0.375 2.156 0.094 2.531 0.938 0.406 0.875 0.25 3.125-0.188 3.938-0.5 0.969-0.438 1.219 0.094 1.375 0.375 0.125 1.563 0.688 2.75 1.313z"></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Intent Identification
                  </h2>
                  <p className="texts-sm">
                    Analyze the intent behind user-generated content to
                    determine the proper response or course of action.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#11416A"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>
                        communication / 16 - communication, bubble, chat,
                        comments, conversation, message icon
                      </title>{" "}
                      <g
                        id="Free-Icons"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        {" "}
                        <g
                          transform="translate(-1265.000000, -156.000000)"
                          id="Group"
                          stroke="#11416A"
                          stroke-width="2"
                        >
                          {" "}
                          <g
                            transform="translate(1263.000000, 154.000000)"
                            id="Shape"
                          >
                            {" "}
                            <path d="M8.29997591,16.1586109 L4.71,18.3 L5.32628923,14.6238888 C3.83281239,13.3921212 3,11.6856969 3,9.8 C3,6.0444637 6.30329488,3 11.55,3 C16.7967051,3 20.1,6.0444637 20.1,9.8 C20.1,13.5555363 16.7967051,16.6 11.55,16.6 C10.3617053,16.6 9.27309774,16.4438342 8.29997591,16.1586109 Z">
                              {" "}
                            </path>{" "}
                            <path d="M19.7000346,12 C20.5370398,12.8311353 21,13.932301 21,15.1401596 C21,16.4401458 20.4637233,17.6165428 19.5020218,18.4657147 L19.8988718,21 L17.587162,19.5237413 C16.9605351,19.7203723 16.259543,19.8280319 15.4943589,19.8280319 C12.7385072,19.8280319 10.8153028,18.4315592 10.2,16.5116586 L10.2,16.5116586">
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Content Detection
                  </h2>
                  <p className="texts-sm">
                    Automatically detect the types of content present in textual
                    data to support content moderation, such as hate speech and
                    other types of inappropriate content.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="42px"
                    height="42px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M3 12H21M12 8V12M6.5 12V16M17.5 12V16M10.1 8H13.9C14.4601 8 14.7401 8 14.954 7.89101C15.1422 7.79513 15.2951 7.64215 15.391 7.45399C15.5 7.24008 15.5 6.96005 15.5 6.4V4.6C15.5 4.03995 15.5 3.75992 15.391 3.54601C15.2951 3.35785 15.1422 3.20487 14.954 3.10899C14.7401 3 14.4601 3 13.9 3H10.1C9.53995 3 9.25992 3 9.04601 3.10899C8.85785 3.20487 8.70487 3.35785 8.60899 3.54601C8.5 3.75992 8.5 4.03995 8.5 4.6V6.4C8.5 6.96005 8.5 7.24008 8.60899 7.45399C8.70487 7.64215 8.85785 7.79513 9.04601 7.89101C9.25992 8 9.53995 8 10.1 8ZM15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V17.6C21 17.0399 21 16.7599 20.891 16.546C20.7951 16.3578 20.6422 16.2049 20.454 16.109C20.2401 16 19.9601 16 19.4 16H15.6C15.0399 16 14.7599 16 14.546 16.109C14.3578 16.2049 14.2049 16.3578 14.109 16.546C14 16.7599 14 17.0399 14 17.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21ZM4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V17.6C10 17.0399 10 16.7599 9.89101 16.546C9.79513 16.3578 9.64215 16.2049 9.45399 16.109C9.24008 16 8.96005 16 8.4 16H4.6C4.03995 16 3.75992 16 3.54601 16.109C3.35785 16.2049 3.20487 16.3578 3.10899 16.546C3 16.7599 3 17.0399 3 17.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21Z"
                        stroke="#11416A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Content Classification
                  </h2>
                  <p className="texts-sm">
                    Build binary classifiers and other classification models for
                    automatically categorizing your content.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    fill="#11416A"
                    width="42px"
                    height="42px"
                    viewBox="-3 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>group</title>{" "}
                      <path d="M20.906 20.75c1.313 0.719 2.063 2 1.969 3.281-0.063 0.781-0.094 0.813-1.094 0.938-0.625 0.094-4.563 0.125-8.625 0.125-4.594 0-9.406-0.094-9.75-0.188-1.375-0.344-0.625-2.844 1.188-4.031 1.406-0.906 4.281-2.281 5.063-2.438 1.063-0.219 1.188-0.875 0-3-0.281-0.469-0.594-1.906-0.625-3.406-0.031-2.438 0.438-4.094 2.563-4.906 0.438-0.156 0.875-0.219 1.281-0.219 1.406 0 2.719 0.781 3.25 1.938 0.781 1.531 0.469 5.625-0.344 7.094-0.938 1.656-0.844 2.188 0.188 2.469 0.688 0.188 2.813 1.188 4.938 2.344zM3.906 19.813c-0.5 0.344-0.969 0.781-1.344 1.219-1.188 0-2.094-0.031-2.188-0.063-0.781-0.188-0.344-1.625 0.688-2.25 0.781-0.5 2.375-1.281 2.813-1.375 0.563-0.125 0.688-0.469 0-1.656-0.156-0.25-0.344-1.063-0.344-1.906-0.031-1.375 0.25-2.313 1.438-2.719 1-0.375 2.125 0.094 2.531 0.938 0.406 0.875 0.188 3.125-0.25 3.938-0.5 0.969-0.406 1.219 0.156 1.375 0.125 0.031 0.375 0.156 0.719 0.313-1.375 0.563-3.25 1.594-4.219 2.188zM24.469 18.625c0.75 0.406 1.156 1.094 1.094 1.813-0.031 0.438-0.031 0.469-0.594 0.531-0.156 0.031-0.875 0.063-1.813 0.063-0.406-0.531-0.969-1.031-1.656-1.375-1.281-0.75-2.844-1.563-4-2.063 0.313-0.125 0.594-0.219 0.719-0.25 0.594-0.125 0.688-0.469 0-1.656-0.125-0.25-0.344-1.063-0.344-1.906-0.031-1.375 0.219-2.313 1.406-2.719 1.031-0.375 2.156 0.094 2.531 0.938 0.406 0.875 0.25 3.125-0.188 3.938-0.5 0.969-0.438 1.219 0.094 1.375 0.375 0.125 1.563 0.688 2.75 1.313z"></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Intent Identification
                  </h2>
                  <p className="texts-sm">
                    Analyze the intent behind user-generated content to
                    determine the proper response or course of action.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="40px"
                    height="40px"
                    viewBox="0 0 20 20"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#11416A"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <title>
                        communication / 16 - communication, bubble, chat,
                        comments, conversation, message icon
                      </title>{" "}
                      <g
                        id="Free-Icons"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        {" "}
                        <g
                          transform="translate(-1265.000000, -156.000000)"
                          id="Group"
                          stroke="#11416A"
                          stroke-width="2"
                        >
                          {" "}
                          <g
                            transform="translate(1263.000000, 154.000000)"
                            id="Shape"
                          >
                            {" "}
                            <path d="M8.29997591,16.1586109 L4.71,18.3 L5.32628923,14.6238888 C3.83281239,13.3921212 3,11.6856969 3,9.8 C3,6.0444637 6.30329488,3 11.55,3 C16.7967051,3 20.1,6.0444637 20.1,9.8 C20.1,13.5555363 16.7967051,16.6 11.55,16.6 C10.3617053,16.6 9.27309774,16.4438342 8.29997591,16.1586109 Z">
                              {" "}
                            </path>{" "}
                            <path d="M19.7000346,12 C20.5370398,12.8311353 21,13.932301 21,15.1401596 C21,16.4401458 20.4637233,17.6165428 19.5020218,18.4657147 L19.8988718,21 L17.587162,19.5237413 C16.9605351,19.7203723 16.259543,19.8280319 15.4943589,19.8280319 C12.7385072,19.8280319 10.8153028,18.4315592 10.2,16.5116586 L10.2,16.5116586">
                              {" "}
                            </path>{" "}
                          </g>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Content Detection
                  </h2>
                  <p className="texts-sm">
                    Automatically detect the types of content present in textual
                    data to support content moderation, such as hate speech and
                    other types of inappropriate content.
                  </p>
                </span>
              </li>
              <li className="flex md:flex-row flex-col text-center md:text-start justify-center items-center md:justify-start md:items-start gap-4">
                <span>
                  <svg
                    width="42px"
                    height="42px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M3 12H21M12 8V12M6.5 12V16M17.5 12V16M10.1 8H13.9C14.4601 8 14.7401 8 14.954 7.89101C15.1422 7.79513 15.2951 7.64215 15.391 7.45399C15.5 7.24008 15.5 6.96005 15.5 6.4V4.6C15.5 4.03995 15.5 3.75992 15.391 3.54601C15.2951 3.35785 15.1422 3.20487 14.954 3.10899C14.7401 3 14.4601 3 13.9 3H10.1C9.53995 3 9.25992 3 9.04601 3.10899C8.85785 3.20487 8.70487 3.35785 8.60899 3.54601C8.5 3.75992 8.5 4.03995 8.5 4.6V6.4C8.5 6.96005 8.5 7.24008 8.60899 7.45399C8.70487 7.64215 8.85785 7.79513 9.04601 7.89101C9.25992 8 9.53995 8 10.1 8ZM15.6 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V17.6C21 17.0399 21 16.7599 20.891 16.546C20.7951 16.3578 20.6422 16.2049 20.454 16.109C20.2401 16 19.9601 16 19.4 16H15.6C15.0399 16 14.7599 16 14.546 16.109C14.3578 16.2049 14.2049 16.3578 14.109 16.546C14 16.7599 14 17.0399 14 17.6V19.4C14 19.9601 14 20.2401 14.109 20.454C14.2049 20.6422 14.3578 20.7951 14.546 20.891C14.7599 21 15.0399 21 15.6 21ZM4.6 21H8.4C8.96005 21 9.24008 21 9.45399 20.891C9.64215 20.7951 9.79513 20.6422 9.89101 20.454C10 20.2401 10 19.9601 10 19.4V17.6C10 17.0399 10 16.7599 9.89101 16.546C9.79513 16.3578 9.64215 16.2049 9.45399 16.109C9.24008 16 8.96005 16 8.4 16H4.6C4.03995 16 3.75992 16 3.54601 16.109C3.35785 16.2049 3.20487 16.3578 3.10899 16.546C3 16.7599 3 17.0399 3 17.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21Z"
                        stroke="#11416A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>{" "}
                    </g>
                  </svg>
                </span>
                <span>
                  <h2 className="text-lg font-medium uppercase text-[#11416A]">
                    Content Classification
                  </h2>
                  <p className="texts-sm">
                    Build binary classifiers and other classification models for
                    automatically categorizing your content.
                  </p>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className=" flex flex-col justify-center items-center">
        <div className="max-w-[1200px] mt-20 flex-col flex gap-4 md:text-start text-center ">
          <p className="text-gray-500 font-medium uppercase lg:w-[1200px]">
            The Innodata Process
          </p>
          <h2 className="md:text-5xl text-3xl text-[#035D9D] mb-20 lg:w-[1200px]">
            An End-to-End Approach
          </h2>
        </div>
        <div className="max-w-[1200px] flex flex-col justify-center items-center mb-20">
          <Timeline />
        </div>
        <hr />
        <div className="max-w-[1200px] mt-20 flex md:flex-row flex-col justify-center items-center ">
          <div className="md:w-1/2 w-4/5 flex flex-col gap-4 justify-center items-center md:justify-start md:items-start md:text-start text-center">
            <h2 className="text-2xl font-medium text-[#035D9D]">
              Our Team of Data Experts
            </h2>
            <p className="text-gray-500">
              Our team is comprised of data experts with years of developing
              strategies that enable companies to manage and distribute data
              using AI-based solutions. Book a time that works for you, and let
              us help develop a custom solution for your unique needs
            </p>
            <a
              href={"/"}
              className=" text-[#11416A] uppercase hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
            >
              REQUEST A DEMO
            </a>
          </div>
          <div className="md:w-1/2 w-4/5 mt-4 md:mt-0 flex justify-center items-center">
            <img src="./Screen-Shot.jpg" alt="" className="rounded-md" />
          </div>
        </div>

        <div className=" md:w-1/2 w-4/5 flex mt-20 flex-col rounded-lg border">
          <div className="bg-[#035D9D] rounded-t-lg w-full text-center  flex flex-col gap-6 p-8 justify-center items-center text-white">
            <h2 className="text-4xl font-medium">Pricing Packages</h2>
            <p className="text-sm uppercase">Text Annotation Services</p>
          </div>
          <div className="bg-[#10416A14] flex flex-col justify-center gap-4 py-8 items-center">
            <p className="w-4/5 text-center mb-4">
              We offer cost effective packages while maintaining the highest
              quality. All of our packages include:{" "}
            </p>
            <ul className="grid md:grid-cols-2 gap-2 w-full px-4 mb-4">
              {listItems.map((item, index) => (
                <li
                  key={index}
                  className="flex gap-2 py-2 justify-start items-center border-b-[1px] px-4"
                >
                  <span>
                    <svg
                      height="20px"
                      width="20px"
                      fill="#035D9D"
                      viewBox="0 0 1920 1920"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          d="M1743.858 267.012 710.747 1300.124 176.005 765.382 0 941.387l710.747 710.871 1209.24-1209.116z"
                          fillRule="evenodd"
                        ></path>
                      </g>
                    </svg>
                  </span>
                  <p className="text-[#035D9D]">{item.text}</p>
                </li>
              ))}
            </ul>
            <a
              href={"/"}
              className=" text-[#11416A] uppercase hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold   transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/3 w-1/2 text-center rounded"
            >
            GET STARTED
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Platforms;
