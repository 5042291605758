import React from "react";
import { Link } from "react-router-dom";

function AudioSpeach() {
  return (
    <div className="w-full">
      <div
        className="relative w-full h-screen flex flex-col justify-start items-center bg-cover bg-center"
        style={{ backgroundImage: `url('/Speech.jpg')` }}
      >
        <div className="absolute inset-0 bg-[#035D9D] opacity-70"></div>
        <div className="relative z-10 max-w-[1200px] flex justify-center lg:top-[15%] mt-10 items-center md:items-start lg:justify-start ">
          <div className="w-4/5 flex flex-col justify-center items-center md:items-start md:justify-start gap-8">
            <p className=" font-bold text-white">Solutions</p>
            <h2 className="md:text-5xl text-2xl md:text-start text-center font-bold text-white">
              Solutions End-to-End Audio and Speech Data Services
            </h2>
            <p className="md:text-xl text-lg font-medium text-white md:text-start text-center">
              Collection, Annotation, Classification, Transcription, and Model
              Development
            </p>
            <Link
              to={"/DataCollection#form"}
              className=" text-[#11416A] hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/4 w-1/2 text-center rounded"
            >
              GET STARTED
            </Link>
          </div>
        </div>

        <div className="bg-[#10416a] relative lg:absolute flex justify-center items-center py-4 lg:mt-0 mt-10 lg:py-14 z-10 w-full bottom-0">
          <div className=" max-w-[1200px] flex lg:flex-row flex-col justify-between items-center  ">
            <div className="lg:w-1/4 w-4/5 border-[#C4D602] border-t-[3px] py-6">
              <h2 className="lg:text-2xl text-center font-medium text-white">
                Collection and Ground Truth Data Capture
              </h2>
            </div>
            <div className="lg:w-1/4 w-4/5 border-[#C4D602] border-t-[3px] py-6">
              <h2 className="lg:text-2xl text-center font-medium text-white">
                Annotation, Classification, and Transcription
              </h2>
            </div>
            <div className="lg:w-1/4 w-4/5 border-[#C4D602] border-t-[3px] py-6">
              <h2 className="lg:text-2xl text-center font-medium text-white">
                AI/ML Model Development Custom Solutions
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full">
        <div className=" max-w-[1200px] flex flex-col justify-center mt-10 items-center ">
          <div className="flex items-center gap-8 flex-col lg:mt-10 mt-64 md:mt-96 justify-center w-4/5">
            <h2 className="text-4xl font-medium text-[#035D9D]  text-center">
              Ground Truth Speech and Audio Data Collection Services
            </h2>
            <div className="h-[2px] w-1/3 bg-[#C4D602]"></div>
            <p className="text-center text-lg text-gray-500">
              With Innodata full suite of audio and speech data collection
              services, you can scale your AI models and ensure model
              flexibility with high-quality and diverse data in multiple
              languages, dialects, demographics, speaker traits, dialogue types,
              environments, and scenarios. Let Innodata global network of 4,000+
              experts, including native speakers of 40+ languages, capture the
              samples you need for any initiative.
            </p>
          </div>

          <div className="grid lg:grid-cols-3 lg:w-full w-4/5 gap-8 mt-20">
            <div className="flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Sample.jpg" alt="" className="rounded" />
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Mixed Environmental and Acoustic Settings
              </h2>
              <p className="text-center text-gray-500">
                From field-recorded audio (like in-home, restaurants, and gyms)
                to in-studio recordings, our diverse situational audio and
                speech data can serve any use case.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./custom.jpg" alt="" className="rounded" />
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Custom Scenarios
              </h2>
              <p className=" text-center text-gray-500">
                With our network of global subject matter experts and in-country
                native-speaking teams, we can provide multi-scenario and
                actor-based scenario recordings for your initiatives in 40+
                languages.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Speaker.jpg" alt="Speaker" className="rounded" />
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Diverse Speaker Traits
              </h2>
              <p className=" text-center text-gray-500">
                Innodata can collect audio and speech data with diverse
                cultural, demographic (like gender and age), sentiment, intent,
                and linguistic characteristics.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Various.jpg" alt="Speaker" className="rounded" />
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Various Dialogue Types
              </h2>
              <p className=" text-center text-gray-500">
                Access to multiple speech dialogue types, like one-speaker
                (monologue), dual-speaker, or multi-speaker conversations.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Mixed-Data.jpg" alt="Speaker" className="rounded" />
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Mixed Data Collection Types
              </h2>
              <p className=" text-center text-gray-500">
                Providing a wide range of recording device scenarios for any AI
                initiative, including audio recorded on hand-held tech,
                telephones, speakers, or computers.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-4 pb-8 rounded-md shadow-lg justify-start items-center">
              <img src="./Flexibility.jpg" alt="Speaker" className="rounded" />
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Flexibility in Sample and Script Requirements
              </h2>
              <p className=" text-center text-gray-500">
                Innodata can provide speech and audio data within any project
                prerequisite, such as overall sample size, the number of
                utterances per speaker, scripted vs. spontaneous speech, and
                natural environments vs. staged scenarios.
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#10416a] text-white flex-col w-full flex justify-center items-center py-8 lg:mt-20  mt-10 ">
          <div className=" lg:w-full w-4/5 flex flex-col gap-4 text-center justify-center items-center ">
            <h2 className="lg:text-xl text-lg font-bold">
              End-to-End Audio and Speech Data Services
            </h2>
            <p className="lg:text-lg ">
              Collection, Annotation, Classification, Transcription, and Model
              Development
            </p>
            <a
              href={"/DataCollection"}
              className=" text-[#11416A] hover:text-white hover:scale-105 py-4 bg-[#C4D602]  text-sm font-semibold transform transition-all duration-300 ease-in-out justify-center items-center flex lg:w-1/5 w-1/2 text-center rounded"
            >
              GET STARTED
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full bg-[#7a7A7A1A]">
        <div className=" max-w-[1200px] flex flex-col justify-center mt-10 items-center ">
          <div className="flex lg:flex-row flex-col lg:w-full gap-4 w-4/5 justify-center lg:justify-between items-center mt-10 mb-10">
            <div className="lg:w-2/5 ">
              <img src="./Audio.jpg" alt="dataset" />
            </div>
            <div className="flex items-center lg:items-start gap-4 flex-col lg:justify-start justify-center lg:w-1/2">
              <h2 className="lg:text-4xl text-xl font-bold lg:font-medium text-[#035D9D] text-center lg:text-start">
                High-Quality Speech and Audio Data Annotation, Classification,
                and Transcription Services
              </h2>
              <div className="h-[2px] w-1/3 bg-[#C4D602]"></div>
              <p className="text-center lg:text-start lg:text-lg text-gray-500">
                With Innodatas full suite of audio and speech data annotation
                services, you can scale your AI models and ensure model
                flexibility with high-quality annotated data. Leverage Innodatas
                deep annotation expertise to streamline audio annotation,
                classification, and transcription using natural language
                processing (NLP) and human experts-in-the-loop.
              </p>
            </div>
          </div>
        </div>

        <div className="max-w-[1200px] flex justify-center items-center py-10 mb-10">
          <ul className="grid md:grid-cols-2 gap-8 md:w-full w-4/5">
            <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
              <span>
                <svg
                  aria-hidden="true"
                  height="42px"
                  width="42px"
                  class="stroke-[#11416A] fill-[#11416A] text-[#10416a] "
                  viewBox="0 0 384 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    class="stroke-[#11416A] fill-[#11416A]"
                    d="M369.941 97.941l-83.882-83.882A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v416c0 26.51 21.49 48 48 48h288c26.51 0 48-21.49 48-48V131.882a48 48 0 0 0-14.059-33.941zM332.118 128H256V51.882L332.118 128zM48 464V48h160v104c0 13.255 10.745 24 24 24h104v288H48zm144-76.024c0 10.691-12.926 16.045-20.485 8.485L136 360.486h-28c-6.627 0-12-5.373-12-12v-56c0-6.627 5.373-12 12-12h28l35.515-36.947c7.56-7.56 20.485-2.206 20.485 8.485v135.952zm41.201-47.13c9.051-9.297 9.06-24.133.001-33.439-22.149-22.752 12.235-56.246 34.395-33.481 27.198 27.94 27.212 72.444.001 100.401-21.793 22.386-56.947-10.315-34.397-33.481z"
                  ></path>
                </svg>
              </span>
              <span>
                <h2 className="text-lg lg:text-2xl font-medium md:text-start uppercase text-[#11416A]">
                  Audio Metadata Segmentation
                </h2>
                <p className="texts-sm">
                  Innodata can partition speech and audio files according to any
                  model-training need, like segmenting different speakers,
                  labeling stop and start times, and tagging speech vs.
                  background noise, music, and silence.
                </p>
              </span>
            </li>
            <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
              <span>
                <svg
                  height="42px"
                  width="42px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path
                      d="M8 12H8.01M12 12H12.01M16 12H16.01M21.0039 12C21.0039 16.9706 16.9745 21 12.0039 21C9.9675 21 3.00463 21 3.00463 21C3.00463 21 4.56382 17.2561 3.93982 16.0008C3.34076 14.7956 3.00391 13.4372 3.00391 12C3.00391 7.02944 7.03334 3 12.0039 3C16.9745 3 21.0039 7.02944 21.0039 12Z"
                      stroke="#10416a"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>{" "}
                  </g>
                </svg>
              </span>
              <span>
                <h2 className="text-lg lg:text-2xl font-medium md:text-start uppercase text-[#11416A]">
                  Speech-to-Text Transcription / Audio Speech Recognition
                </h2>
                <p className="texts-sm">
                  Our human experts-in-the-loop and deep NLP expertise can
                  provide industry-leading transcriptions for any verbatim or
                  non-verbatim initiative, saving you time, labor, and cost.
                </p>
              </span>
            </li>
            <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
              <span>
                <svg
                  aria-hidden="true"
                  height="42px"
                  width="42px"
                  class="stroke-[#11416A] fill-[#11416A] text-[#10416a] "
                  viewBox="0 0 384 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M336 192h-16c-8.84 0-16 7.16-16 16v48c0 74.8-64.49 134.82-140.79 127.38C96.71 376.89 48 317.11 48 250.3V208c0-8.84-7.16-16-16-16H16c-8.84 0-16 7.16-16 16v40.16c0 89.64 63.97 169.55 152 181.69V464H96c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h160c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16h-56v-33.77C285.71 418.47 352 344.9 352 256v-48c0-8.84-7.16-16-16-16zM176 352c53.02 0 96-42.98 96-96h-85.33c-5.89 0-10.67-3.58-10.67-8v-16c0-4.42 4.78-8 10.67-8H272v-32h-85.33c-5.89 0-10.67-3.58-10.67-8v-16c0-4.42 4.78-8 10.67-8H272v-32h-85.33c-5.89 0-10.67-3.58-10.67-8v-16c0-4.42 4.78-8 10.67-8H272c0-53.02-42.98-96-96-96S80 42.98 80 96v160c0 53.02 42.98 96 96 96z"></path>
                </svg>
              </span>
              <span>
                <h2 className="text-lg lg:text-2xl font-medium md:text-start uppercase text-[#11416A]">
                  Speaker Intent and Mood/Sentiment Labeling
                </h2>
                <p className="texts-sm">
                  Innodata can annotate audio sentiment and intent, like speech
                  intensity, context, word rate, pitch, changes in pitch, and
                  stress — for use in initiatives like customer experience
                  needs, call center dialogues, estimating customers' opinions,
                  and monitoring product or brand reputation.
                </p>
              </span>
            </li>
            <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
              <span>
                <svg
                  aria-hidden="true"
                  height="42px"
                  width="42px"
                  class="stroke-[#11416A] fill-[#11416A] text-[#10416a] "
                  viewBox="0 0 496 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm82.29 357.6c-3.9 3.88-7.99 7.95-11.31 11.28-2.99 3-5.1 6.7-6.17 10.71-1.51 5.66-2.73 11.38-4.77 16.87l-17.39 46.85c-13.76 3-28 4.69-42.65 4.69v-27.38c1.69-12.62-7.64-36.26-22.63-51.25-6-6-9.37-14.14-9.37-22.63v-32.01c0-11.64-6.27-22.34-16.46-27.97-14.37-7.95-34.81-19.06-48.81-26.11-11.48-5.78-22.1-13.14-31.65-21.75l-.8-.72a114.792 114.792 0 0 1-18.06-20.74c-9.38-13.77-24.66-36.42-34.59-51.14 20.47-45.5 57.36-82.04 103.2-101.89l24.01 12.01C203.48 89.74 216 82.01 216 70.11v-11.3c7.99-1.29 16.12-2.11 24.39-2.42l28.3 28.3c6.25 6.25 6.25 16.38 0 22.63L264 112l-10.34 10.34c-3.12 3.12-3.12 8.19 0 11.31l4.69 4.69c3.12 3.12 3.12 8.19 0 11.31l-8 8a8.008 8.008 0 0 1-5.66 2.34h-8.99c-2.08 0-4.08.81-5.58 2.27l-9.92 9.65a8.008 8.008 0 0 0-1.58 9.31l15.59 31.19c2.66 5.32-1.21 11.58-7.15 11.58h-5.64c-1.93 0-3.79-.7-5.24-1.96l-9.28-8.06a16.017 16.017 0 0 0-15.55-3.1l-31.17 10.39a11.95 11.95 0 0 0-8.17 11.34c0 4.53 2.56 8.66 6.61 10.69l11.08 5.54c9.41 4.71 19.79 7.16 30.31 7.16s22.59 27.29 32 32h66.75c8.49 0 16.62 3.37 22.63 9.37l13.69 13.69a30.503 30.503 0 0 1 8.93 21.57 46.536 46.536 0 0 1-13.72 32.98zM417 274.25c-5.79-1.45-10.84-5-14.15-9.97l-17.98-26.97a23.97 23.97 0 0 1 0-26.62l19.59-29.38c2.32-3.47 5.5-6.29 9.24-8.15l12.98-6.49C440.2 193.59 448 223.87 448 256c0 8.67-.74 17.16-1.82 25.54L417 274.25z"></path>
                </svg>
              </span>
              <span>
                <h2 className="text-lg lg:text-2xl font-medium md:text-start uppercase text-[#11416A]">
                  Speaker Trait Identification
                </h2>
                <p className="texts-sm">
                  Similar to our world-class speech and audio data collection
                  trait variabilities, we can label traits like languages,
                  dialects, accents, and demographics (like gender and age)
                  within audio files.
                </p>
              </span>
            </li>
            <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
              <span>
                <svg
                  aria-hidden="true"
                  height="42px"
                  width="42px"
                  class="stroke-[#11416A] fill-[#11416A] text-[#10416a] "
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"></path>
                </svg>
              </span>
              <span>
                <h2 className="text-lg lg:text-2xl font-medium md:text-start uppercase text-[#11416A]">
                  Flexibility in Sample and Project Requirements
                </h2>
                <p className="texts-sm">
                  Innodata can provide speech and audio data annotation within
                  any project prerequisite, including transcription
                  requirements, annotation requirements, delivery method, and
                  delivery schedule.
                </p>
              </span>
            </li>
            <li className="flex md:flex-row flex-col justify-center text-center md:text-start items-center md:justify-start md:items-start gap-4">
              <span>
                <svg
                  aria-hidden="true"
                  height="42px"
                  width="42px"
                  class="stroke-[#11416A] fill-[#11416A] text-[#10416a] "
                  viewBox="0 0 640 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"></path>
                </svg>
              </span>
              <span>
                <h2 className="text-lg lg:text-2xl font-medium md:text-start uppercase text-[#11416A]">
                  Audio Classification
                </h2>
                <p className="texts-sm">
                  In addition to our audio and speech annotation offerings, our
                  global subject matter experts can classify files into broader
                  pre-established categories, like recording quality, amount of
                  background noise, speaker intents, music vs. no music,
                  conversational topics, speaker languages and dialects, the
                  number of speakers, and more.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center w-full mt-10">
        <div className=" max-w-[1200px] flex flex-col justify-center mt-10 items-center ">
          <div className="flex lg:flex-row flex-col-reverse lg:w-full gap-4 w-4/5 justify-center lg:justify-between items-center mt-10 mb-10">
            <div className="flex items-center lg:items-start gap-4 flex-col lg:justify-start justify-center lg:w-1/2">
              <h2 className="lg:text-4xl text-xl font-bold lg:font-medium text-[#035D9D] text-center lg:text-start">
                Speech and Audio AI/ML Model Development
              </h2>
              <div className="h-[2px] w-1/3 bg-[#C4D602]"></div>
              <p className="text-center lg:text-start lg:text-lg text-gray-500">
                Scale your virtual assistants, ASR or text-to-speech models,
                conversational AI, wearables, and other NLP initiatives with
                Innodata end-to-end services. Whether you use our collected or
                annotated data, or need help utilizing your existing data to
                deploy or develop speech and audio AI/ML models, Innodata can
                help you expedite time-to-market. Utilize our world-class
                subject matter experts to build, train, and deploy models,
                augment your team, prevent model drift, and scale your models
                and operations faster.
              </p>
            </div>
            <div className="lg:w-2/5 ">
              <img src="./Model.jpg" alt="dataset" className="rounded-md" />
            </div>
          </div>
          <div className="grid lg:grid-cols-3 lg:w-full w-4/5 gap-8 mt-20">
            <div className="flex flex-col gap-4 py-4 px-8 pb-8 rounded-md shadow-lg justify-start items-center">
              <svg
                aria-hidden="true"
                height="50px"
                width="50px"
                class="stroke-gray-400 fill-gray-400 text-[#10416a] "
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M384 320H256c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V352c0-17.67-14.33-32-32-32zM192 32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v128c0 17.67 14.33 32 32 32h95.72l73.16 128.04C211.98 300.98 232.4 288 256 288h.28L192 175.51V128h224V64H192V32zM608 0H480c-17.67 0-32 14.33-32 32v128c0 17.67 14.33 32 32 32h128c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32z"></path>
              </svg>
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Model Deployment
              </h2>
              <p className="text-center text-sm text-gray-500">
                Innodata can build, train, and deploy customized audio and
                speech AI and ML models to support your use-case and
                specifications built on your desired framework.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-8 pb-8 rounded-md shadow-lg justify-start items-center">
              <svg
                aria-hidden="true"
                height="50px"
                width="50px"
                class="stroke-gray-400 fill-gray-400 text-[#10416a] "
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"></path>
              </svg>
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Staff Augmentation
              </h2>
              <p className="text-center text-sm text-gray-500">
                When you need to scale your team or deploy a one-off initiative,
                we have the resources to help. Use Innodata experts to avoid
                hiring, training, and developing staff internally.
              </p>
            </div>
            <div className="flex flex-col gap-4 py-4 px-8 pb-8 rounded-md shadow-lg justify-start items-center">
              <svg
                aria-hidden="true"
                height="50px"
                width="50px"
                class="stroke-gray-400 fill-gray-400 text-[#10416a] "
                viewBox="0 0 640 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M448 73.143v45.714C448 159.143 347.667 192 224 192S0 159.143 0 118.857V73.143C0 32.857 100.333 0 224 0s224 32.857 224 73.143zM448 176v102.857C448 319.143 347.667 352 224 352S0 319.143 0 278.857V176c48.125 33.143 136.208 48.572 224 48.572S399.874 209.143 448 176zm0 160v102.857C448 479.143 347.667 512 224 512S0 479.143 0 438.857V336c48.125 33.143 136.208 48.572 224 48.572S399.874 369.143 448 336z"></path>
              </svg>
              <h2 className="text-2xl text-[#035D9D] font-bold text-center">
                Data Drift Prevention
              </h2>
              <p className="text-center text-sm text-gray-500">
                We can help identify issues in data quality, integrity problems,
                demographic shifts, and changes in workforce bias/behavior. We
                then utilize various learning types, periodic retraining with
                new high-quality data, and the introduction of weighted data to
                get the confidence scores you need.
              </p>
            </div>
          </div>
        </div>
        
      </div>

      <div className="flex flex-col justify-center items-center w-full bg-[#7a7A7A1A] mt-20">
        <svg
          aria-hidden="true"
          class="stroke-white fill-white transform scale-x-[-1] left-20 "
          viewBox="0 0 1000 100"
          xmlns="http://www.w3.org/2000/svg"
          transform="scaleX(-1)"
        >
          <path class="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"></path>
        </svg>
        <svg
          aria-hidden="true"
          class="stroke-white fill-white absolute lg:left-20 left-4 lg:-mt-30 -mt-20 lg:h-[75px] lg:w-[75px] h-[50px] w-[50px]"
          viewBox="0 0 640 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
        </svg>
        <svg
          aria-hidden="true"
          class="stroke-white fill-white absolute lg:mt-40 mt-48 right-[14%] lg:h-[75px] lg:w-[75px] h-[50px] w-[50px]"
          viewBox="0 0 640 512"
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(180)"
        >
          <path d="M464 256h-80v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8c-88.4 0-160 71.6-160 160v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zm-288 0H96v-64c0-35.3 28.7-64 64-64h8c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24h-8C71.6 32 0 103.6 0 192v240c0 26.5 21.5 48 48 48h128c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"></path>
        </svg>
        <div className="lg:max-w-[1200px] w-4/5 flex flex-col gap-8 py-10 justify-center mt-10 items-center ">
          <h2 className="text-[#11416A] lg:text-2xl text-center font-medium">
            Our machine learning projects are highly dependent on accurately
            annotated data, and Innodata has a wide reach to experts that can
            make sense of some of the complex datasets we work with.
          </h2>
          <img src="./pypestream.png" className="w-6 h-6 rounded-full" alt="" />
          <div className="flex flex-col gap-2">
            <h3 className="uppercase text-sm text-center font-bold text-[#11416A]">
              Rahul Garg
            </h3>
            <p className="text-sm text-[#11416A] text-center">
              Chief Product Officer, Pypestream
            </p>
          </div>
        </div>
        <svg
          aria-hidden="true"
          class="stroke-white fill-white"
          viewBox="0 0 1000 100"
          xmlns="http://www.w3.org/2000/svg"
          transform="rotate(180)"
        >
          <path class="elementor-shape-fill" d="M0,6V0h1000v100L0,6z"></path>
        </svg>
      </div>
    </div>
  );
}

export default AudioSpeach;
