import React, { useEffect, useRef, useState } from "react";

const Timeline = () => {
  const timelineRef = useRef(null);
  const [lineHeight, setLineHeight] = useState(0);
  const scrollSpeed = 0.5; // Adjust this factor to slow down or speed up the line

  useEffect(() => {
    const handleScroll = () => {
      const timeline = timelineRef.current;
      const timelineTop = timeline.getBoundingClientRect().top + window.scrollY;
      const windowHeight = window.innerHeight;

      // Calculate how much of the timeline should be filled
      const scrolledDistance =
        (window.scrollY + windowHeight - timelineTop) * scrollSpeed;
      const totalHeight = timeline.scrollHeight;

      // Set the height of the line, ensuring it's within valid bounds
      const newLineHeight = Math.min(
        Math.max(scrolledDistance, 0),
        totalHeight
      );
      setLineHeight(newLineHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const timelineData = [
    {
      heading: "Prepare",
      text: "Consult with a dedicated account manager. Generate test pilot to fine-tune annotation specifications to meet clients ML needs. Align text annotation goals. Establish quality metrics, KPIs, & SLAs. A flexible & iterative approach.",
    },
    {
      heading: "Activate",
      text: "A tailored team of in-house SMEs are selected based on project requirements and individual domain expertise. Annotators complete a customized training program after which they receive weekly audit reports, showing the results of auto-validation, random QC spot checks, and KPI performance evaluations. ​",
    },
    {
      heading:"Launch",
      text: "Our text annotation services and platform offer various workbenches with unparalleled control of annotation workflows. Time-to-value enhancers augment and streamline work. Highly accurate annotated data. Infinite scale. ",
    },
    {
      heading:"Deliver",
      text: "Continuous delivery of ground-truth annotated text data to power your text classification and NLP models. Secure data transfers. Strengthen model weaknesses with iterative batches to facilitate active learning. ​",
    },
    
  ];

  const TimelineItem = ({ data }) => (
    <div className="timeline-item">
      <div className="timeline-item-content border">
        <h2 className="text-2xl text-[#035D9D]">{data.heading}</h2>
        <p className="text-sm text-gray-500">{data.text}</p>
        <span className="circle" />
      </div>
    </div>
  );

  return (
    <div className="timeline-container " ref={timelineRef}>
      <div className="timeline-line" style={{ height: `${lineHeight}px` }} />
      {timelineData.map((data, idx) => (
        <TimelineItem data={data} key={idx} />
      ))}
    </div>
  );
};

export default Timeline;
